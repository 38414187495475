<template>
  <div class="product-component">
    <v-row>
      <div v-for="(category, index1) in items" :key="index1">
        <div class="category-name">
          <!-- {{ category.trans.title }} -->
          {{ category.name }}
        </div>
      
          <!--===============================================================================-->
        <!--<div class="slider-componpent">-->
          <!--===============================================================================--> 
        <div class="vv">
          <v-col cols="12" md="12" sm="12" xs="12" >
            <div class="vvv"  v-for="(item, index) in category.products" :key="index">
              <!-- v-if="item.category_id === category.id" -->
              <v-card cols="5" sm="5" xs="5" class="mx-auto"  >
                  <v-list-item-avatar
                    cols="5" md="5" sm="5" xs="5"
                    size=""
                    style=""
                    height="350"
                  >
                    <!-- <img :src="image_base_url + item.avatar" :alt="item.trans.name" /> -->
                    <a :href="item.avatar" class="img-holder" data-fancybox>
                      <img :src="item.avatar" class="bg-cover" :alt="item.name" />
                    </a>
                  </v-list-item-avatar>
                <v-list-item class="list" three-line>
                  
                  <v-list-item-content cols="12" md="12" sm="12" xs="12">
                    <v-list-item-title class="title"  v-html="item.name" dir="rtl">
                      <!-- {{ item.trans.name }} -->
                    </v-list-item-title>
                    <div class="price">{{ item.price }} 
                                            <!-- sar currncy-->
                      <img class="sar-img" src="../assets/images/Sar.png" />
                    </div>
                    <div class="unt">
                    {{ item.mini_order }} {{ item.unit }}
                    </div>
                    <!-- <v-list-item-subtitle v-html="item.trans.description"> -->
                    <v-list-item-subtitle v-html="item.description" dir="rtl">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  
                </v-list-item>
                <div class="d-flex d-rtl" style="bottom: 0px; margin-bottom:0px">
                  <!--<v-btn class="mr-3 btn" rounded text disabled style="padding: 0px;">
                    {{ item.mini_order }} {{ item.unit }}
                  </v-btn> -->
                  <div v-if="item.available_quantity > 0" class="input">
                    <!-- rgb(84, 84, 84); <input type="number" v-model="item.quantity" readonly disabled /> -->
                    <p
                      @click.prevent
                      style="
                        padding: 1px 0 1px 0;
                        border: 1px solid #555;
                        width: 85%;
                        border-radius: 7px;
                        text-align: center;
                        color:  #4c9e53
                      "
                    >
                      {{ item.quantity }}
                    </p>
                    <v-icon class="add" @click.prevent="increase(item)">
                      mdi-plus
                    </v-icon>
                    <v-icon class="minus" @click.prevent="decrease(item)">
                      mdi-minus
                    </v-icon>
                  </div>
                  <div v-else>
                    <v-text-field
                      value="نفذت الكمية"
                      filled
                      disabled
                      class="w-auto enough"
                    ></v-text-field>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </div>  
        <!--</div>-->
      </div>
    </v-row>

    <div class="footer-contact">
      <img src="../static/payment-footer.png" alt="payment" />
    </div>
    <div class="footer-buttons">
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <!-- flex hover-->
          <div class="one one11">
            <div cols="2" md="2" sm="2" xs="2" class="btn right_left two" style="text-align: center;">
            <a href="tel:+966554466539"
            > <i class="fas fa-phone"></i
            ></a>
            </div>
          </div>
          <!-- flex hover-->
          <div cols="6" md="6" sm="6" xs="6" class="btn right_left one1122" style="text-align: center; padding: 0px 7% ">
            <router-link to="/contact-us"
              > حسابات التواصل <i class="fab fa-snapchat-ghost"></i
            ></router-link>
          </div>
          <!--<div cols="4" md="4" sm="4" xs="4" class="btn right_left" style="text-align: center">
            <router-link to="/policy"
              > سياسة الاسترجاع <i class="fa fa-whatsapps"></i
            ></router-link>
          </div>-->
          
          <!-- flex hover-->
          <div class="one one22">
            <div  cols="2" md="2" sm="2" xs="2" class="btn right_left two" style="text-align: center;">
            <a href="https://wa.me/966554466539" target="_blank"
                >
              <!-- <a href="https://wa.me/966582280459?text=السلام عليكم , متجر سمو النخيل أريد الاستفسار عن: %0A">-->
                <i class="fab fa-whatsapp" style=" "></i
              >
              </a>
            </div>
          </div>
          <!-- flex hover-->
        </v-col>
      </v-row>
    </div>
    
    <a
      @click="checkCart()"
      class="add-cart"
      style="
        position: fixed !important;
        bottom: 2px !important;
        margin-bottom: 12px;
      "
    >
    <span >{{ totalSummation }} </span>
    <span >{{ price }} </span>
        
      إستكمال الشراء <i class="fas fa-shopping-basket"></i>
    </a>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text class="text-center">
          <span> لا يوجد عناصر تم اختيارها</span>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success green-btn" @click="dialog = false">
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="500">
      <v-card>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text class="text-center">
          <span> عذرًا الحد الأدنى للطلب {{ minimum_order }} ريال</span>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success green-btn" @click="dialog2 = false">
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :z-index="zIndex" :value="overlay" :opacity="opacity">
      <v-icon @click.stop="overlay = !overlay"> mdi-close </v-icon>
      <v-img :src="image" width="500px"></v-img>
    </v-overlay>
  </div>

</template>

  <!--    -------------------------------------------------------     -->

<script>

// import { mapMutations } from "vuex";

import { mapGetters } from "vuex";

import { ServiceFactory } from "../services/ServiceFactory";
const productsService = ServiceFactory.get("product");
import { Fancybox } from "@fancyapps/ui";
export default {
  /*props: ["totalSummation"],*/
  data: (vm) => ({

    /*---------------------------------------------*/
    categories: [],
    formCards: [],
    totalAmount: '',
    headers: [
      { text: "الاسم", value: "name", sortable: false },
      { text: "الكمية", value: "quantity", sortable: false },
      { text: "سعر الوحدة", value: "price", sortable: false },
    ],
  /*---------------------------------------------*/



    // allProducts: [],
    // allProducts: [
    //   {
    //     id: 1,
    //     name: "محشي",
    //     description: "محشي ورق عنب",
    //     price: 10,
    //     category_id: 1,
    //     avatar: "https://tamratkaram.com/Areas/040320204163050020.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    //   {
    //     id: 2,
    //     category_id: 1,
    //     name: "محشي",
    //     price: 10,
    //     description: "محشي ورق عنب",
    //     avatar: "https://tamratkaram.com/Areas/040320201945354020.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    //   {
    //     id: 3,
    //     category_id: 1,
    //     name: "محشي",
    //     price: 10,
    //     description: "محشي ورق عنب",
    //     avatar: "../assets/images/product-1.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    // ],
    // allCategories: [],
    // allCategories: [
    //   {
    //     id: 1,
    //     title: "قسم",
    //   },
    //   {
    //     id: 2,
    //     title: " 2قسم",
    //   },
    // ],
    items: [],
    product: {},
    dialog: false,
    dialog2: false,
    quantity: 0,
    productId: "",
    image_base_url: "",
    overlay: false,
    zIndex: 2000000,
    opacity: 0.88,
    image: "",
    scrollOffset: 0,
    minimum_order: 60,
    totalSummation: ""
  }),

  created() {
    this.getData();


    /*------------------------------------------------------*/
    this.totalMoneyInInvoice();
    /*------------------------------------------------------*/


    // console.log(this.allProducts);
    // this.fetchAllItems();
    // this.getAllCategories();
    // if (this.$route) {
    // }
  },
  computed: {
    counter() {
      return this.$store.state.quantity;
    },

    /*--------------------------------------*/
    ...mapGetters(["total"]),
    products() {
      return this.$store.state.products;
    },
    /*--------------------------------------*/


  },
  watch: {
    overlay: {
      handler(value) {
        if (!value) {
          document.documentElement.scrollTop = this.scrollOffset;
        }
      },
    },
  },

  methods: {
    showOverlay(avatar) {
      this.image = avatar;
      // save current scroll offset
      // eslint-disable-next-line no-undef
      this.scrollOffset = $(window).scrollTop();
      this.overlay = true;
      var _this = this;
      // eslint-disable-next-line no-undef
      $(document).ready(function () {
        // eslint-disable-next-line no-undef
        $(".v-overlay__scrim").click(function () {
          _this.overlay = false;
        });
      });
    },
    increase(item) {
      // console.log(index + index1);
      // console.log("quantity", item.quantity + 1);
      // console.log("max_stock_per_order", item.max_stock_per_order);
      if (item.available_quantity > item.quantity) {
        if (
          item.max_stock_per_order == null ||
          item.max_stock_per_order >= parseInt(item.quantity + 1)
        ) {
          item.quantity++;
          this.$store.commit("increase", {
            product_id: item.id,
            product_name: item.name,
            price: item.price,
            quantity: item.quantity,
            Kg: item.mini_order,
            product_unit: item.product_unit,
            max_stock_per_order: item.max_stock_per_order,
          });
        } else {
          alert("عذراً ، لقد وصلت للحد الاقصى من هذا العنصر فى الطلب الواحد");
        }
      } else {
        alert("للأسف الكمية المتاحة لا تكفى المطلوب");
      }
      // let products = this.allProducts;
      // this.allProducts = null;
      // this.allProducts = products;
    },
    decrease(item) {
      // console.log('')
      if (item.quantity >= 1) {
        item.quantity--;
        this.$store.commit("decrease", {
          product_id: item.id,
          product_name: item.name,
          price: item.price,
          quantity: item.quantity,
          Kg: item.mini_order,
        });
        // let products = this.allProducts;
        // this.allProducts = null;
        // this.allProducts = products;
      }
    },

    async getData() {
      this.dataLoading = true;
      const data = await productsService.getAllData();
      this.items = data.products;
      this.minimum_order = data.minimum_order;
      // console.log(this.items[0].name);

      this.dataLoading = false;
    },
    // async fetchAllItems() {
    //   this.dataLoading = true;
    //   const data = await productsService.getAllProductsData();
    //   console.log(data.data.Products.rows);
    //   this.allProducts = data.data.Products.rows;
    //   this.image_base_url = data.data.image_base_url;
    //   if (this.allProducts.length > 0) {
    //     this.allProducts.map((item) => {
    //       item.quantity = 0;
    //       return item;
    //     });
    //   }
    //   this.dataLoading = false;
    // },
    // async getAllCategories() {
    //   this.dataLoading = true;
    //   const data = await productsService.getAllCategoriesData();
    //   this.allCategories = data.data.Categories.rows;
    //   this.dataLoading = true;
    // },
    checkCart() {
      if (this.$store.state.products.length > 0) {
        console.log(this.$store.state.products);
        let totalPrice = 0;
        this.$store.state.products.forEach((product) => {
          totalPrice += parseInt(product.quantity) * parseInt(product.price);
        });
        if (totalPrice >= this.minimum_order) {
          this.$router.push("/checkout");
        } else {
          this.dialog2 = true;
        }
      } else {
        this.dialog = true;
      }
    },
  },
  mounted() {
    Fancybox.bind("[data-fancybox]", {
      // Your custom options
    });
  },

/*---------------------------------------------------*/

  totalMoneyInInvoice() {
      this.totalAmount = this.products.map(
        (item) => (item.total = item.quantity * item.price)
      );
      this.totalAmount = this.products.reduce((n, { total }) => n + total, 0);
    },

    /*---------------------------------------------------*/


};

</script>
<style>

.product-component {
  direction: ltr;
  padding-bottom: 60px;
}
.category-name {
  text-align: center;
  color: #614c37;
  font-size: 17px;
  /*box-shadow: 0 2px 11px rgba(0, 0, 0, 0.18) !important;*/

}
.green-btn {
  background-color: #4c9e53;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.product-component .v-card {
  margin-bottom: 15px;
  flex-direction: column !important;
  display:flex;
  width: 96.5%;
  height: 275px;

}
.product-component .title {
  color: #4c9e53;
  font-size: 15px !important;
  margin: 0px;
}
.product-component .price {
  /*color: #614c37;*/
  color: #eff0ef;
  font-weight: 600;
  font-size: 13.7px;
  position: absolute;
  top: -25.2%;
  left: 1%;
  direction: rtl;
  background: #4c9e53;
  padding: 1.8%;
  border-radius: 0 7px;
}
.product-component .unt{
  color: #eff0ef;
  font-weight: 600;
  font-size: 13.7px;
  position: absolute;
  top: -21%;
  right: 1.7%;
  direction: rtl;
  /*background: #ffBA1c;*/
  background: #ffBA1c;
  padding: 1.8% !important;
  border-radius: 7px 0 7px 0;
}
.product-component img {
  /*box-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);*/
  border:3px solid #fff;
  /*display:block !important;*/
}

.product-component .v-sheet.v-card {
  padding-bottom: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18) !important;
  background: #fff;
  border-radius: 20px;
}
.v-application p {
  padding: 0px !important;
  margin: 0px !important;
}
.product-component .v-list-item__avatar{
  border-radius: 0px;
  display:flex !important;
  margin-bottom: 0;
  margin: 2px 0px 0px 0px !important;
  border-radius: 22px 22px 0 13px;
  /*box-shadow: 0 3px 11px rgba(0, 0, 0, 0.18) !important;*/
  /*height:350px !important*/
}
/* ===============*/

/* ===============*/
.product-component
  .v-application--is-ltr
  .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0 !important;
}
.product-component .d-rtl {
  direction: rtl;
}
.product-component .d-rtl .btn {
  background: #ffBA1c;
  color: #fff !important;
  width: 10%;
  height: 31px;
  padding: 1px;
  text-align: center;
  border-radius: 7px;
  margin-left: 15px;
}
.product-component .d-rtl .input {
  position: relative;
  width: 85%;
  right: 14%;
}
.v-list-item--three-line {
padding: 0 9px;
}
/* descriptiuon */
.v-list-item--three-line .v-list-item__subtitle {
  font-size: 12px;
  color: #767676;
  /*min-height: 36.8px;*/
}
.product-component .d-rtl .input input {
  padding: 5px 0 5px 0;
  border: 1px solid #555;
  width: 100%;
  border-radius: 7px;
  text-align: center;
}
.product-component .d-rtl .input .add {
  position: absolute;
  right: 6%;
  top: 6px;
  font-size: 18.5px;
  /* z-index: 20; */
  background-color: #fff;
}
.product-component .d-rtl .input .minus {
  position: absolute;
  /*left: 25%;*/
  right: 55%;
  top: 6px;
  font-size: 18.5px;
  /* z-index: 20; */
  background-color: #fff;
}
 /*start of the نفذت الكمية*/
.w-auto {
  text-align: center !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  width: 60%;
  padding-right: 0;
  margin: 0 0 -5px;
  min-height: auto;

}  
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea{
  text-align: center;
}
 /*end of the نفذت الكمية*/
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid #333;
  border-radius: 5px;
  right: 30%;
}
.img-holder {
  /* position: absolute; */
  /* right: 16px;
  top: 10px; */

  cursor: pointer;
  overflow: hidden;
}
.img-holder img {
  width: 100%;
  height: 100%;
  /*border-radius: 50px;*/
  border: 3px solid #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);
}
.footer-contact {
  text-align: center;
  margin-top: 4px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
  background: #fff;
  border-radius: 60px;
  margin: 0 0 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
}
.footer-contact img {
  border-radius: 0;
  height: 40px;
  box-shadow: none;
}
.footer-buttons {
  width: 90%;
  align-items: center !important;
  justify-content:space-between !important; 
  position: relative !important;
}
.footer-buttons .right_left {
  display: inline-block;
  position: relative !important;
  margin-right: 2%;
  }
  /* flex hover whatsapp + caller */
.one{
  position: fixed;
  z-index: 50;
  bottom: 71px;
  }
.two{
  display: flex;
}
.one11{
  left:10%;
}
.one22{
  right:10%
}
.one1122{
  margin-right: 17% !important;
}
  /* flex hover whatsapp + caller */

.footer-buttons a {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff !important;
  border-radius: 50px;
  padding: 0 15px 2px;
  /*display:block;*/
  color: #3f3f3f !important;
  text-decoration: unset;
}
.add-cart {
  /* position: fixed; */
  /* left: 20px; */
  /* right: 20px; */
  z-index: 99;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  max-width: 570px;
  margin: auto;
  margin-top: 20px;
  margin-left: .4%;
  bottom: 20px;
  display: block;
  text-align: center;
  background-color: #4c9e53;
  border-radius: 37px;
  padding: 9px;
  cursor: pointer;
  width: 100%;
  border: none;
  color: #fff !important;
  text-decoration: unset;
  font-family: "frutiger lt arabic 55 roman"
}
.add-cart a {
  color: #fff;
  text-decoration: unset;
}



@font-face {
  font-family: 'SARFont';
  src: url('/assets/fonts/sarfont-woff.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}.riyalsymbol {
  font-family: 'SARFont', sans-serif !important;
}

@media only screen and (max-width: 426px) {
  .add-cart {
    left: 1.5%;
    right: unset;
    width: 96%;
  }
  /* description */
  .v-list-item--three-line .v-list-item__subtitle {
  font-size: 12px !important;
  min-height: 30px;
  padding: 0px !important;
  overflow:visible;
  margin-bottom: 10px;
}
  .product-component .title {
    font-size: 13.5px !important;
    overflow:visible;
  }
  /*نفذت الكمية*/
  .enough {
    width: 100% !important;
    margin: 0 0 -5px 0;
    min-height: auto;
    padding: 0 !important;
    font-size:smaller;
  }
  /*.v-text-field__slot <input{
    width: 80% !important;
  }*/
  .v-text-field input{
    width:100% ;
  }
  /* v-list-item-subtitle{
    font-size: xx-small !important;
  }*/
  .product-component .price {
    top: -21.2% !important;
    left: 1.6% !important;
    font-size: 13.3px;
  }
  .product-component .unt{
    top: -21%;
    right: 1.7%;

  }
  .footer-buttons {
    /*margin-left: 0 !important;*/
    width: 95%;
  }
}
@media only screen and (max-width: 376px) {
  .add-cart {
    left: 1.5%;
    right: unset;
    width: 96%;
  }
  /* description */
  .v-list-item--three-line .v-list-item__subtitle {
  font-size: 12px !important;
  min-height: 30px;
  overflow:visible;
  margin-bottom: 10px;

}
  .product-component .title {
  font-size: 13.5px !important;
  }
  .footer-buttons {
    width: 95%;
    
  }

  .product-component .price{
    top: -22.5% !important;
    left: 1.6% !important;
  }
  .product-component .unt{
    top: -21%;
    right: 1.7%;
  }
  
  .footer-buttons .right_left {
    margin-left: 0px;
  }
  .enough {
    width: 100% !important;
    margin: 0 0 -5px 0;
    min-height: auto;
    padding-right: 0 !important;

  }
}
@media only screen and (max-width: 321px) {
  .add-cart {
    left: 1.5%;
    right: unset;
    width: 96%;
  }
  /* description */
  .v-list-item--three-line .v-list-item__subtitle {
  font-size: 12px !important;
  min-height: 30px;
  overflow:visible;
  margin-bottom: 10px;

  }
  .product-component .title {
  font-size: 13.5px !important;
  }

  .enough {
    width: 100% !important;
    margin: 0 0 -5px 0;
    min-height: auto;
    padding-right: 0 !important;
  }
  .footer-buttons {
    width:96%;
  }
  .product-component .price{
    top: -22.5% !important;
    left: 1.6% !important;
  }
  .product-component .unt{
    top: -21%;
    right: 1.7%;
  }
  .footer-buttons .right_left {
    margin-left: 0%;
  }
  /*.v-text-field.v-text-field--enclosed .v-text-field__details {
    width:86%;
    padding: 0 41%;
    margin: 0 0 -5px 0;
  }*/
  .theme--light.v-input--is-disabled input {
    font-size: smaller !important;
  }
}
/* responsive iphone 14pro max*/
@media only screen and (width:430px){
  .product-component {
    /*width:80%;*/
  }
  .product-component .title {
  font-size: 13.5px !important;
  overflow:visible;
  }
  .v-list-item--three-line .v-list-item__subtitle {
    font-size: 12px !important;
    min-height: 36.8px !important;
    overflow:visible;
    margin-bottom: 10px;
    /*font-family: "frutiger lt arabic 55 roman" !important;*/
    /*font-family: "Gill Sans", sans-serif !important;*/

}
}

/* ------------------------------------------------------------------*/
.invoice-component {
  width: 100%;
}
.invoice-component .paper-head {
  margin-bottom: 10px;
  position: relative;
}
.invoice-component .paper-head img {
  width: 100%;
}
.invoice-component .paper-head::after {
  content: "";
  position: absolute;
  background-image: url(../static/paper.png);
  bottom: -17px;
  right: 7.3%;
  left: 7.3%;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
}
.theme--light.v-data-table .v-data-footer {
  display: none;
}
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  text-align: right;
}
.name-green {
  color: #4c9e53;
}
.invoice-component .paper-item {
  background-color: #fff;
  /* margin: 0 7.3%;
  padding: 5px 20px 15px; */
  margin: 0 7.3%;
  padding: 5px 10px 15px;
  position: relative;
}
.invoice-component .paper-item::after {
  content: "";
  position: absolute;
  background-image: url(../static/paper.png);
  top: -22px;
  right: 0;
  left: 0;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
  transform: rotate(180deg);
}
.invoice-component .complete-btn {
  display: block;
  text-align: center;
  background-color: #4c9e53;
  color: #fff;
  border-radius: 37px;
  padding: 9px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  border: none;
  font-family: "frutiger lt arabic 55 roman" !important;
}

.mx-au{
  width: 50%;
  display:inline-block;
}
.slider-component {
  border-radius: 20px;
  /* box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18); */
  /*height: 330px !important;
  background-color: #f5f5f5;
}
.theme--light.v-image {
  height: max-content !important;
}

.slider-component .theme--light.v-card {
  background: whitesmoke;
}
.slider-component v-carousel-item {
  border-radius: 20px;
  height: 340px !important;
  /* background-color: #f5f5f5; */
}
.vv{
  width: 100%;
}
.vvv{
  display:inline-block !important;
  width: 50%;
 /* height: 236px;*/
  margin-bottom: 0px;
  flex-direction: column !important;
  
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  box-sizing: content-box;

}
.s-product-card-entry {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: space-between;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: 300ms;
}
.sar-img{
  height: 15px;
  width: 20px;
  border-style: none;
  border: 2px solid transparent;
  padding: -5px;
  margin: 0px;
  border:0px solid #fff !important;
 
}
</style>
